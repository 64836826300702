import React, { useState, useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Cta from "../components/cta";
import Hero from "../components/hero";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Brands from "../components/brands";
import parse from "html-react-parser";

function AboutUs({ data }) {
    let d = data.contentfulAboutUs;

    const [brandsTitle, setBrandsTitle] = useState([]);
    useEffect(() => {
        fetch(`https://app.ngorder.id/API/shops`)
            .then((response) => response.json())
            .then((resultData) => {
                let count = resultData.shops.toString();
                let countThousand = count.substr(0, count.length - 3) + "rb++";
                setBrandsTitle(`${countThousand} ${d.brands.title}`);
            });
    }, []);

    // Fetch stats
    const [stats, setStats] = useState([]);
    useEffect(() => {
        fetch(`https://app.ngorder.id/API/growth`)
            .then((response) => response.json())
            .then((r) => {
                let data = {
                    shops: r.shops,
                    users: r.users,
                    reseller: r.customers.reseller,
                };

                setStats(data);
            });
    }, []);

    return (
        <Layout>
            <SEO title={d.title} description={d.subtitle} />

            <Hero
                props={{
                    padding: `10`,
                    title: d.title,
                    desc: d.subtitle,
                }}
            />

            <div className="flex md:flex-row-reverse flex-col-reverse items-center w-full max-w-6xl mx-auto py-8 px-4 md:px-20">
                <div className="flex-1 w-full paragraph">
                    <h2 className="text-2xl md:text-4xl leading-tight mb-6 mt-2 font-display">
                        {d.contentLeftTitle}
                    </h2>
                    {renderRichText(d.contentLeftText)}
                </div>

                <div className="flex-1 max-w-full mb-4 md:p-12">
                    <img src={d.contentLeftImage.file.url} alt="" />
                </div>
            </div>

            <div className="flex md:flex-row flex-col-reverse items-center w-full max-w-6xl mx-auto py-8 px-4 md:px-20">
                <div className="flex-1 w-full paragraph">
                    <h2 className="text-2xl md:text-4xl leading-tight mb-6 mt-2 font-display">
                        {d.contentRightTitle}
                    </h2>
                    {renderRichText(d.contentRightText)}
                </div>

                <div className="flex-1 max-w-full mb-4 md:p-12">
                    <img src={d.contentRightImage.file.url} alt="" />
                </div>
            </div>

            <section className="bg-white dark:bg-gray-700">
                <div className="w-full max-w-5xl mx-auto py-8 md:py-16">
                    <div className="flex flex-col md:flex-row">
                        {d.milestone.map((item) => (
                            <div
                                className="flex flex-row-reverse md:flex-col items-start w-full md:w-1/5 h-auto border-b border-primary p-4 hover:bg-gray-50 dark:hover:bg-opacity-10 cursor-pointer"
                                key={item.year}
                            >
                                <div className="flex-auto mb-4">
                                    <ul>
                                        {item.content.map((x) => (
                                            <li
                                                key={x}
                                                className="flex mb-3 items-start leading-tight"
                                            >
                                                <img
                                                    className="mr-2 w-3 h-3 mt-1"
                                                    src="../icons/arrow.svg"
                                                />

                                                <span>{parse(x)}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className="flex-none w-28">
                                    <h4 className="font-sans font-bold text-2xl ml-4 text-primary dark:text-gray-200 leading-none">
                                        {item.year}
                                    </h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="bg-white dark:bg-gray-800">
                <div className="w-full max-w-5xl mx-auto py-8 md:py-16">
                    <h3 className="text-xl md:text-3xl leading-tight mb-6 mt-2 font-display text-center max-w-4xl mx-auto px-6">
                        {d.stats.title}
                    </h3>

                    <div className="w-full flex flex-col md:flex-row md:space-x-10 py-10">
                        {[
                            {
                                icon: "user-profile.14.svg",
                                stat: stats.shops,
                                text: d.stats.content[0],
                            },
                            {
                                icon: "users-circle.svg",
                                stat: stats.users,
                                text: d.stats.content[1],
                            },
                            {
                                icon: "group-user.1.svg",
                                stat: stats.reseller,
                                text: d.stats.content[2],
                            },
                        ].map((item) => (
                            <div
                                className="flex md:flex-1 p-4 pl-10 md:p-4 space-x-3 items-start"
                                key={item.text}
                            >
                                <img
                                    src={`../icons/myicons/${item.icon}`}
                                    alt=""
                                    className="w-10"
                                />

                                <div>
                                    <h4 className="text-4xl font-sans font-extralight leading-none mb-2 text-primary">
                                        {parseInt(item.stat).toLocaleString(
                                            "id-ID"
                                        )}
                                    </h4>
                                    <p className="text-gray-600 dark:text-gray-400 leading-tight">
                                        {item.text}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="bg-white dark:bg-gray-800">
                <Brands title={brandsTitle} content={[...Array(7).keys()]} />
            </section>

            <Cta />
        </Layout>
    );
}

AboutUs.propTypes = {
    data: PropTypes.object,
};
export const query = graphql`
    {
        contentfulAboutUs {
            title
            subtitle
            contentLeftTitle
            contentLeftText {
                raw
            }
            contentLeftImage {
                file {
                    url
                }
            }
            contentRightTitle
            contentRightText {
                raw
            }
            contentRightImage {
                file {
                    url
                }
            }
            milestone {
                year
                content
            }
            ourLeads {
                title
                content {
                    name
                    title
                }
            }
            brands {
                title
            }
            stats {
                title
                content
            }
        }
    }
`;

export default AboutUs;
